import { ApiUrl } from '@/constants';
import { CmsSettings } from '@/types';
import { NextApiRequest, NextApiResponse } from 'next';

import { respondError, tokenizedApiCall } from '@/global/utils/Api';

export const getSettingsServerSide = async (req: NextApiRequest, res: NextApiResponse): Promise<CmsSettings | void> => {
  const url = `${ApiUrl.cmsApi}/settings`;

  try {
    const tokenizedResponse = await tokenizedApiCall(url, req);
    const { data } = await tokenizedResponse.json();
    return data || null;
  } catch (error) {
    return respondError(res, 500, { error, message: 'Failed to fetch cms settings', url });
  }
};
