import NextImage, { type ImageProps, StaticImageData } from 'next/image';
import React, { useMemo } from 'react';
import placeholderImage1 from 'public/images/placeholder1.webp';
import placeholderImage2 from 'public/images/placeholder2.webp';
import placeholderImage3 from 'public/images/placeholder3.webp';
import placeholderImage4 from 'public/images/placeholder4.webp';
import placeholderImage5 from 'public/images/placeholder5.webp';

const defaultImages = [placeholderImage1, placeholderImage2, placeholderImage3, placeholderImage4, placeholderImage5];

function seededRandom(blurImageSeed: number) {
  const x = Math.sin(blurImageSeed) * 10000;
  return x - Math.floor(x);
}

const pickRandomPlaceholderImage = (blurImageSeed: number, imageOverride?: StaticImageData[]) => {
  const images = imageOverride || defaultImages;
  const pickedImage = images[Math.floor(seededRandom(blurImageSeed) * images.length)];
  return pickedImage?.blurDataURL || '';
};

type LazyLoadingImageProps = ImageProps & {
  blurImageOverride?: StaticImageData[];
  seed?: number; // optionally use seed for random blur image selection
};

const LazyLoadingImage = (props: LazyLoadingImageProps) => {
  const { priority = true, seed = 100, sizes = '(max-width: 768px) 100vw, 50vw', blurImageOverride, ...rest } = props;
  const blurDataURL = useMemo(() => pickRandomPlaceholderImage(seed, blurImageOverride), [blurImageOverride, seed]);

  return (
    <NextImage
      placeholder={priority ? 'empty' : 'blur'}
      blurDataURL={blurDataURL}
      loading={priority ? 'eager' : 'lazy'}
      priority={priority}
      sizes={sizes}
      {...rest}
    />
  );
};

export default LazyLoadingImage;
