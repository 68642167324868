import { ApiUrl } from '@/constants';
import {
  ApiLoginErrorResponse,
  ApiResponseError,
  BankIdChallengePayload,
  BankIdChallengeResponse,
  BankIdInitializePayload,
  BankIdInitializeResponse,
  BankIdPollStatusResponse,
  EmailPasswordLoginPayload,
  EmailPasswordLoginResponse,
} from '@/types';

import { gretch } from '@/global/utils/Api';

export const emailPasswordLogin = async (payload: EmailPasswordLoginPayload) => {
  const url = `${ApiUrl.authApi}/login`;

  const { status, error } = await gretch<EmailPasswordLoginResponse, ApiLoginErrorResponse>(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).json();

  if (status === 204) return;

  throw error;
};

export const bankIdChallenge = async (challengePayload: BankIdChallengePayload) => {
  const url = `${ApiUrl.authApi}/external-login/challenge`;

  const { status, error } = await gretch<number, BankIdChallengeResponse>(url, {
    hooks: undefined, // we get a 401 that we don't want to redirect on
    method: 'POST',
    body: JSON.stringify(challengePayload),
  }).json();

  // Challenge for a non logged in user will result in 401
  if (status === 401 && error) return error;

  throw error;
};

export const bankIdInitialize = async (url: string, payload: BankIdInitializePayload) => {
  const { data, error } = await gretch<BankIdInitializeResponse, ApiLoginErrorResponse>(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).json();

  if (data) return data;

  throw error;
};

export const bankIdPollStatus = async (statusUrl: string) => {
  const statusUrlNoCache = `${statusUrl}&_=${Date.now()}`;
  const { data, error } = await gretch<BankIdPollStatusResponse, ApiLoginErrorResponse>(statusUrlNoCache).json();

  if (data) return data;

  throw error;
};

export const bankIdCancel = async (cancelUrl: string, orderRef: string) => {
  const { status, error } = await gretch<number, ApiLoginErrorResponse>(cancelUrl, {
    method: 'POST',
    body: JSON.stringify({
      orderRef,
    }),
  }).json();

  if (status === 204) return;

  throw error;
};

export const forgotPassword = async (email: string) => {
  const url = `${ApiUrl.authApi}/forgot-password/send-password-reset-email`;

  const { status, error } = await gretch<number, ApiResponseError>(url, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  }).json();

  if (status === 204) return;

  throw error;
};

export const updatePassword = async (password: string, token: string) => {
  const url = `${ApiUrl.authApi}/forgot-password/password`;

  const { status, error } = await gretch<number, ApiResponseError>(url, {
    method: 'PATCH',
    body: JSON.stringify({
      password,
      token,
    }),
  }).json();

  if (status === 204) return;

  throw error;
};

export const bankIdCallback = async (callbackUrl: string, callbackData: string, antiforgeryToken: string) => {
  await fetch(callbackUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `uiResult=${callbackData}&__RequestVerificationToken=${antiforgeryToken}`,
  }).then((response) => {
    if (response.redirected) {
      // Redirect the user to the URL provided by the server
      window.location.href = response.url;
    }
  });
};
