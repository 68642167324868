import StickerPair from '@/newComponents/Sticker/StickerPair';
import ToggleInfo from '@/newComponents/ToggleInfo/ToggleInfo';
import styled, { css } from 'styled-components';

import { ContentWidth, above } from '@/global/style/breakpoint';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';

export const Wrapper = styled.section<{ $backgroundColor: string }>`
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const ContentWrapper = styled.div<{ $bleed?: boolean }>`
  overflow: hidden;
  border-radius: ${NewBorderRadius.Medium};
  display: flex;
  flex-direction: column;
  gap: ${spacing.x3};

  padding: ${spacing.x2};

  ${({ $bleed }) =>
    $bleed
      ? css`
          padding: ${spacing.x7} ${spacing.x2};

          ${above.md`
            padding: ${spacing.x9} ${spacing.x5} ${spacing.x9} ${spacing.x5};
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: ${spacing.x6};
          `}
        `
      : css`
          ${above.md`
            padding: ${spacing.x25} ${spacing.x3} ${spacing.x2} ${spacing.x3};
          `}
        `}
`;

export const StyledStickerPair = styled(StickerPair)<{ $bleed?: boolean }>`
  width: 100%;
  justify-content: center;

  ${({ $bleed }) =>
    $bleed &&
    css`
      ${above.md`
        justify-content: flex-end;
      `}
    `}
`;

export const TextButtonWrapper = styled.div<{ $bleed?: boolean }>`
  display: grid;
  gap: ${spacing.x2};
  width: 100%;

  ${({ $bleed }) =>
    $bleed &&
    css`
      ${above.md`
        gap: ${spacing.x3};
        max-width: ${ContentWidth.textMax};
      `}
    `}
`;

export const StyledToggleInfo = styled(ToggleInfo)`
  border-top: 1px solid ${Color.Hallon5};
`;
