import SvgIcon, { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';

import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { getCmsTextWithValues } from '@/global/utils/Cms';
import { CmsColorSchemeKey, getColorFromColorScheme } from '@/global/utils/CmsBlocks/GetColorFromColorScheme';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import { StyledLi, StyledUl } from './CheckmarkList.styled';
import { Orientation } from './CheckmarkList.types';

export type CheckmarkListProps = {
  data?: string[];
  orientation?: Orientation;
  columns?: number;
  className?: string;
  colorScheme?: CmsColorSchemeKey;
  foregroundColor?: Color;
  fontWeight?: FontWeight;
  fontType?: TypographyType.Body | TypographyType.Detail;
  icon?: IconKey;
};

const CheckmarkList = (props: CheckmarkListProps) => {
  const {
    data = [],
    orientation = Orientation.Horizontal,
    columns = 1,
    className = '',
    colorScheme = CmsColorSchemeKey.Normal,
    foregroundColor,
    fontWeight = FontWeight.SemiBold,
    fontType = TypographyType.Detail,
    icon = 'check',
  } = props;
  const getColor = getColorFromColorScheme(colorScheme);
  const textColor = foregroundColor ?? getColor?.text;
  const accentColor = foregroundColor ?? getColor?.accent;

  return (
    <StyledUl className={className} $columns={columns < 1 ? 1 : columns} $orientation={orientation}>
      {data?.map((item) => (
        <StyledLi $orientation={orientation} key={item} $color={textColor}>
          <SvgIcon
            icon={icon}
            size="small"
            circleColor={accentColor}
            bgColor={Color.Transparent}
            fill={Color.Transparent}
            checkmarkColor={accentColor}
          />
          <Typography
            type={fontType}
            color={textColor}
            fontWeight={fontWeight}
            text={<HtmlFromCMS html={getCmsTextWithValues(item)} />}
          />
        </StyledLi>
      ))}
    </StyledUl>
  );
};

export default CheckmarkList;
