import { ApiUrl } from '@/constants';
import { ApiResponseError, GetProductsResponse } from '@/types';
import { NextApiRequest, NextApiResponse } from 'next';

import { gretch, respondError, tokenizedApiCall } from '@/global/utils/Api';

export const getProducts = async (packageIds: string[]) => {
  const filterPackageIds = packageIds.filter((id) => id.length > 0);

  const productsQuery = new URLSearchParams();
  filterPackageIds.forEach((packageId) => productsQuery.append('id', packageId));

  const { data, error } = await gretch<GetProductsResponse, ApiResponseError>(
    `${ApiUrl.webClientApi}/products/?${productsQuery.toString()}`
  ).json();

  if (data) return data.data;

  throw error;
};

export const getProductsServerSide = async (req: NextApiRequest, res: NextApiResponse, packageIds: string[]) => {
  try {
    const filterPackageIds = packageIds.filter((id) => id.length > 0);
    const productsQuery = new URLSearchParams();
    filterPackageIds.forEach((packageId) => productsQuery.append('id', packageId));

    const url = `${ApiUrl.webClientApi}/products/?${productsQuery.toString()}`;
    const tokenizedResponse = await tokenizedApiCall(url, req);
    const { data } = await tokenizedResponse.json();
    return data;
  } catch (error) {
    return respondError(res, 500, { error, message: 'Failed to fetch cms settings' });
  }
};
