import {
  ApiResponseError,
  Brand,
  ErrorMessageReferenceKey,
  ErrorMessageWrappedInContent,
  GtmLineItem,
  Offer,
  OfferType,
  PageTrackingProps,
  Product,
} from '@/types';

import { gtmAddToCart } from '@/global/gtm';
import { generateGtmLineItemFromRaw, getTotalCartValue } from '@/global/gtm/helpers';
import { UtmTracking } from '@/global/hooks/useTracking';
import { createCart } from '@/global/services/checkout';

import { setCartCookie } from './Checkout/Checkout';
import { getCmsErrorObjectByReference } from './Cms';

export type AddToCartParams = {
  brand: Brand;
  offer?: Offer;
  productData?: Product;
  parentSubscriptionId?: string;
  hardwareProductReference?: string;
  connectHardwareToExistingSubscriptionId?: string;
  userId?: string | null;
  utmTracking: UtmTracking | null;
  salesTracking: PageTrackingProps | null;
  gtmHwLineItem?: GtmLineItem;
  promotionName?: string;
};

export const addToCart = async (params: AddToCartParams) => {
  const {
    offer,
    brand,
    productData,
    parentSubscriptionId,
    hardwareProductReference,
    connectHardwareToExistingSubscriptionId,
    userId,
    utmTracking = {},
    gtmHwLineItem,
    salesTracking = {},
    promotionName,
  } = params;

  const cart = await createCart({
    customerContext: brand,
    userId: userId || '',
    lineItem: {
      productReference: offer?.id.toString() ?? undefined,
      parentSubscriptionId,
      hardwareProductReference,
      connectHardwareToExistingSubscriptionId,
      lineItemType: hardwareProductReference ? OfferType.Hardware : OfferType.Offer,
      ...salesTracking,
      cmsContextPageId: salesTracking?.cmsContextPageId?.toString(),
      ...utmTracking,
    },
  });

  const gtmCartItems = [];
  if (productData && offer && !connectHardwareToExistingSubscriptionId) {
    gtmCartItems.push(generateGtmLineItemFromRaw(offer, productData));
  }

  if (gtmHwLineItem) {
    gtmCartItems.push(gtmHwLineItem);
  }

  gtmAddToCart(gtmCartItems, getTotalCartValue(gtmCartItems), promotionName);
  setCartCookie(cart, brand === Brand.B2B);

  return cart;
};

export const getAddToCartError = (
  error: ApiResponseError,
  errorMessages?: ErrorMessageWrappedInContent<string>[],
  userBrand?: Brand
) => {
  let errorReferenceKey: string = ErrorMessageReferenceKey.AddToCartError;

  if (error?.data?.message === ErrorMessageReferenceKey.UserContextMismatch) {
    if (userBrand === Brand.B2B) {
      errorReferenceKey = `${ErrorMessageReferenceKey.UserContextMismatch}_B2B`;
    } else {
      errorReferenceKey = `${ErrorMessageReferenceKey.UserContextMismatch}_B2C`;
    }
  }

  const cmsError = getCmsErrorObjectByReference({
    cmsErrors: errorMessages || [],
    errorReferenceKey,
  })?.content;

  return {
    header: cmsError?.header ?? 'Kunde inte lägga till produkten i varukorgen.',
    text: cmsError?.body ?? 'Vänligen försök igen senare.',
  };
};
