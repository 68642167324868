'use client';

import Button from '@/newComponents/Button/Button';
import { ButtonColor, ButtonSize } from '@/newComponents/Button/Button.utils';

import CheckmarkList from '@/newComponents/CheckmarkList/CheckmarkList';
import { Orientation } from '@/newComponents/CheckmarkList/CheckmarkList.types';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import { StickerVariant } from '@/newComponents/Sticker/StickerTypes';
import { ToggleInfoHoverVariant } from '@/newComponents/ToggleInfo/ToggleInfo.styled';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import type {
  ApiResponseError,
  BlockItemProps,
  CmsSettings,
  CrossSellingBlock as CrossSellingBlockType,
} from '@/types';
import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';

import { gtmSelectPromotion } from '@/global/gtm';
import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { useAppContext } from '@/global/hooks/useAppContext';
import { useGetProduct } from '@/global/hooks/useGetProduct';
import useMatchMedia from '@/global/hooks/useMatchMedia';
import useToastNotice from '@/global/hooks/useToastNotice';
import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import useTracking from '@/global/hooks/useTracking';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { breakpoints } from '@/global/style/breakpoint';
import { addToCart, getAddToCartError } from '@/global/utils/AddToCart';
import { getNeutralCmsTheme } from '@/global/utils/Cms';
import { CmsColorSchemeKey } from '@/global/utils/CmsBlocks/GetColorFromColorScheme';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import {
  ContentWrapper,
  StyledStickerPair,
  StyledToggleInfo,
  TextButtonWrapper,
  Wrapper,
} from './CrossSellingBlock.styled';

type CrossSellingBlockProps = {
  data: CrossSellingBlockType['content'];
  parentSubscriptionId?: string;
  bleed?: boolean;
} & BlockItemProps;

const CrossSellingBlock = (props: CrossSellingBlockProps) => {
  const { data, parentSubscriptionId, bleed = true, userId } = props;

  const {
    $type,
    buttonText,
    buttonType,
    buttonLink,
    faqs,
    header,
    infoStickerHeader,
    infoStickerLine2,
    infoStickerLine3,
    offer,
    priceStickerHeader,
    priceStickerLine4,
    priceStickerPreHeader,
    priceStickerSubHeader,
    theme: themeKey,
    usps,
  } = data;
  const [isLoading, setIsLoading] = useState(false);
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const { errorMessages } = settings;
  const { account } = useAppContext();
  const { type: userBrand } = account || {};
  const isMobile = useMatchMedia(`(max-width:${breakpoints.sm})`);
  const productData = useGetProduct(offer?.ratorPackageId) ?? undefined;
  const offerItem = offer && productData && generateGtmLineItemFromRaw(offer, productData);
  const router = useRouter();
  const shouldAddToCart = Boolean(offer);
  const theme = getNeutralCmsTheme(themeKey);
  const { utmTracking, salesTracking } = useTracking();
  const toast = useToastNotice();
  const ref = useRef(null);
  useTrackPromotionViewed(ref, $type, header, shouldAddToCart, offerItem);

  const addProductToCart = async () => {
    try {
      if (!offer || !productData) {
        throw new Error('Missing offer or product data');
      }

      setIsLoading(true);

      await addToCart({
        offer,
        productData,
        parentSubscriptionId,
        brand: productData.brand,
        userId,
        utmTracking,
        salesTracking,
      });

      router.push('/checkout');
    } catch (error) {
      console.error('Failed to add product to cart', error); // eslint-disable-line no-console
      toast({
        dataTestId: 'add-to-cart-error-toast',
        preset: NoticeFieldPreset.Error,
        ...getAddToCartError(error as ApiResponseError, errorMessages, userBrand),
      });
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    if ($type && header) {
      gtmSelectPromotion($type, header, offerItem || null);
    }

    if (shouldAddToCart) {
      return addProductToCart();
    }

    router.push(buttonLink?.url ?? '');
  };

  return (
    <Wrapper $backgroundColor={theme.backgroundColor} ref={ref}>
      <ContentWrapper $bleed={bleed}>
        <StyledStickerPair
          $bleed={bleed}
          priceStickerHeader={priceStickerHeader ?? ''}
          priceStickerPreheader={priceStickerPreHeader}
          priceStickerSubheader={priceStickerSubHeader}
          priceStickerPreamble={priceStickerLine4}
          infoStickerHeader={infoStickerHeader}
          infoStickerPreamble1={infoStickerLine2}
          infoStickerPreamble2={infoStickerLine3}
          variant={StickerVariant.Light}
          isMobile={isMobile}
        />
        <TextButtonWrapper $bleed={bleed}>
          <Typography type={TypographyType.HeadingS} text={header} />

          {usps && usps.length > 0 && (
            <CheckmarkList
              orientation={Orientation.Vertical}
              data={usps.map(({ content }) => content.text)}
              colorScheme={CmsColorSchemeKey.Black}
            />
          )}
          {buttonText && (
            <Button
              color={buttonType ? ButtonColor[buttonType] : ButtonColor.Primary}
              size={ButtonSize.Small}
              onClick={handleButtonClick}
              data-testid="add-extra-user-button"
              isLoading={isLoading}
              label={buttonText}
            />
          )}
          {faqs?.map(({ content }) => (
            <StyledToggleInfo
              header={content.header}
              key={content.alias}
              data-testid="extra-user-faq-toggle"
              hoverVariant={ToggleInfoHoverVariant.Hallon}
            >
              <Typography type={TypographyType.Body} text={<HtmlFromCMS html={content.text} />} />
            </StyledToggleInfo>
          ))}
        </TextButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CrossSellingBlock;
