import { CmsPageTrackingSessionStorageKey, GclidSessionStorageKey } from '@/constants';
import { TrackingCmsProduct } from '@/types';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export type UtmTracking = {
  utmMedium?: string;
  utmSource?: string;
};

// We register utmTracking properties when the user enteres hallon from a link in Google -> Handled in _app.tsx
// We also register pageSalesTracking properties from the cms page -> Handled in [...slug].tsx
const useTracking = () => {
  const searchParams = useSearchParams();
  const utmMedium = searchParams?.get('utm_medium') || undefined;
  const utmSource = searchParams?.get('utm_source') || undefined;

  const [tracking, setTracking] = useState<{
    utmTracking: UtmTracking | null;
    salesTracking: TrackingCmsProduct | null;
  }>({ utmTracking: null, salesTracking: null });

  useEffect(() => {
    // Race condition with _app.tx setting of gclid query parameter
    setTimeout(() => {
      const gclid = sessionStorage.getItem(GclidSessionStorageKey);
      const cmsPageTrackingString: string | null = sessionStorage.getItem(CmsPageTrackingSessionStorageKey);

      let cmsPageTracking: TrackingCmsProduct | null = null;
      if (cmsPageTrackingString) {
        cmsPageTracking = JSON.parse(cmsPageTrackingString) as TrackingCmsProduct;
      }

      let utmTracking: UtmTracking | null = null;
      if (gclid) {
        utmTracking = {
          utmMedium: 'cpc',
          utmSource: 'google',
        };
      } else {
        utmTracking = {
          utmMedium,
          utmSource,
        };
      }

      setTracking({
        utmTracking,
        salesTracking: cmsPageTracking,
      });
    }, 0);
  }, [utmMedium, utmSource]);

  return tracking;
};

export default useTracking;
