import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { spacing } from '@/global/style/variables';

import { Orientation } from './CheckmarkList.types';

export const StyledUl = styled.ul<{ $columns?: number; $orientation?: Orientation }>`
  display: inline-grid;
  flex-direction: column;

  ${({ $columns, $orientation }) =>
    $orientation === Orientation.Vertical
      ? css`
          display: grid;
          grid-template-columns: repeat(${$columns}, minmax(0, 1fr));
        `
      : css`
          ${above.sm`
            display: inline;
            text-align: center;
          `}
          ${above.xxs`
            grid-template-columns: minmax(0, 1fr);
           `}
        `};
`;

export const StyledLi = styled.li<{ $color?: string; $orientation?: Orientation }>`
  display: inline-flex;
  gap: ${spacing.x1};
  align-items: start;
  break-inside: avoid;
  :not(:last-child) {
    margin-bottom: ${spacing.x1};
  }

  ${({ $orientation }) =>
    $orientation === Orientation.Horizontal &&
    css`
      :not(:last-child) {
        margin-left: ${spacing.x2};
      }
    `}

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;
