import { getProducts } from '@/api';
import { Product } from '@/types';
import { useEffect, useState } from 'react';

export const useGetProduct = (ratorPackageId?: string) => {
  const [product, setProduct] = useState<Product | null>(null);
  useEffect(() => {
    if (!ratorPackageId) {
      return;
    }
    getProducts([ratorPackageId]).then((res) => {
      setProduct(res[0]);
    });
  }, [ratorPackageId]);
  return product;
};
