import { Color } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

export enum CmsColorSchemeKey {
  DeepBlue = 'deep blue',
  DeepGreen = 'deep green',
  DeepRed = 'deep red',
  Normal = 'normal',
  White = 'white',
  Black = 'black', // todo: not sure this should even be here. Perhaps we need to update the color schemes?
}

export type ColorScheme = {
  text: HallonColor;
  header: HallonColor;
  accent: HallonColor;
};

export const getColorFromColorScheme = (colorScheme?: CmsColorSchemeKey): ColorScheme | undefined => {
  const defaultColorScheme = { text: Color.MineShaft, header: Color.Hallon1, accent: Color.Hallon1 };
  if (!colorScheme) {
    return undefined;
  }

  switch (colorScheme.toLowerCase()) {
    case CmsColorSchemeKey.White:
      return { text: Color.White, header: Color.White, accent: Color.White };
    case CmsColorSchemeKey.Black:
      return { text: Color.MineShaft, header: Color.MineShaft, accent: Color.MineShaft };
    case CmsColorSchemeKey.DeepRed:
      return { text: Color.MineShaft, header: Color.Hallon1, accent: Color.MineShaft };
    case CmsColorSchemeKey.DeepGreen:
      return { text: Color.MineShaft, header: Color.Forest, accent: Color.MineShaft };
    case CmsColorSchemeKey.DeepBlue:
      return { text: Color.MineShaft, header: Color.Blue, accent: Color.MineShaft };
    case CmsColorSchemeKey.Normal:
    default:
      return defaultColorScheme;
  }
};
