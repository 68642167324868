import { GtmLineItem } from '@/types';
import { RefObject, useEffect, useState } from 'react';

import { gtmViewPromotion } from '@/global/gtm';
import { useIsInView } from '@/global/hooks/useIsInView';
import { getPercentageOfElementThatFitsInView } from '@/global/utils/elementCalculations';

export const useTrackPromotionViewed = (
  ref: RefObject<HTMLElement>,
  creativeName?: string,
  promotionName?: string,
  waitForOfferItem?: boolean,
  offerItem?: GtmLineItem | null
) => {
  const viewPortPercentageOfElement = getPercentageOfElementThatFitsInView(ref);
  const threshold = Math.min(0.5, viewPortPercentageOfElement / 2);

  const isInView = useIsInView(ref, { threshold });
  const [hasBeenTracked, setHasBeenTracked] = useState(false);
  const [hasBeenInView, setHasBeenInView] = useState(false);

  useEffect(() => {
    if (isInView) {
      setHasBeenInView(true);
    }
  }, [isInView]);

  useEffect(() => {
    if (hasBeenTracked || !hasBeenInView || !creativeName || !promotionName || (waitForOfferItem && !offerItem)) {
      return;
    }
    gtmViewPromotion(creativeName, promotionName, offerItem || null);
    setHasBeenTracked(true);
  }, [creativeName, hasBeenInView, hasBeenTracked, offerItem, promotionName, waitForOfferItem]);
};
