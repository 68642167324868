import { StickerVariant } from '@/newComponents/Sticker/StickerTypes';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import React from 'react';
import styled from 'styled-components';

import { Color, NewBorderRadius, spacing } from '@/global/style/variables';

function getBackgroundColor(variant?: StickerVariant) {
  switch (variant) {
    case StickerVariant.Light:
      return Color.Hallon5;
    case StickerVariant.Dark:
    default:
      return Color.Hallon2;
  }
}
function getFontColor(variant?: StickerVariant) {
  switch (variant) {
    case StickerVariant.Light:
      return Color.Hallon1;
    case StickerVariant.Dark:
    default:
      return Color.White;
  }
}

const StickerBackground = styled.div<{ $variant?: StickerVariant }>`
  transform: rotate(-7deg);
  background-color: ${({ $variant }) => getBackgroundColor($variant)};

  padding: ${spacing.x2};
  border-radius: ${NewBorderRadius.Large};
  min-width: calc(136px + ${spacing.x2} * 2);
  width: min-content;
  user-select: none;
`;

const Header = styled(Typography).attrs({
  type: TypographyType.ExpressiveS,
  align: 'center',
})`
  margin-bottom: ${spacing.x1};
  line-height: 120%;
`;

const Preamble = styled(Typography).attrs({
  type: TypographyType.Micro,
  align: 'center',
})`
  :not(:last-child) {
    margin-bottom: ${spacing.x1};
  }
`;

export interface RectangleStickerProps {
  infoStickerHeader: string;
  infoStickerPreamble1?: string;
  infoStickerPreamble2?: string;
  variant?: StickerVariant;
}

const RectangleSticker: React.FC<RectangleStickerProps> = ({
  variant,
  infoStickerPreamble1,
  infoStickerPreamble2,
  infoStickerHeader,
}) => {
  const fontColor = getFontColor(variant);
  return (
    <StickerBackground $variant={variant}>
      <SvgIcon icon="like" color={fontColor} size="large" />
      <Header color={fontColor} text={infoStickerHeader} elementAs="span" />
      <Preamble color={fontColor} text={infoStickerPreamble1} />
      <Preamble color={fontColor} text={infoStickerPreamble2} />
    </StickerBackground>
  );
};

export default RectangleSticker;
