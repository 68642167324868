import OvalSticker, { OvalStickerProps } from '@/newComponents/Sticker/OvalSticker';
import RectangleSticker, { RectangleStickerProps } from '@/newComponents/Sticker/RectangleSticker';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ $willRenderLeftAsRectangular: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${({ $willRenderLeftAsRectangular }) => ($willRenderLeftAsRectangular ? 0 : '-16px')};
  }
`;

export type StickerPairProps = OvalStickerProps &
  Partial<RectangleStickerProps> & {
    className?: string;
  };

const StickerPair: React.FC<StickerPairProps> = ({
  className,
  isMobile,
  priceStickerHeader,
  priceStickerPreheader,
  priceStickerSubheader,
  priceStickerPreamble,
  infoStickerHeader,
  infoStickerPreamble1,
  infoStickerPreamble2,
  variant,
}) => {
  return (
    <Wrapper className={className} $willRenderLeftAsRectangular={!!priceStickerPreamble}>
      {priceStickerHeader && (
        <OvalSticker
          priceStickerHeader={priceStickerHeader}
          priceStickerPreheader={priceStickerPreheader}
          priceStickerSubheader={priceStickerSubheader}
          priceStickerPreamble={priceStickerPreamble}
          isMobile={isMobile}
          variant={variant}
        />
      )}
      {infoStickerHeader && (
        <RectangleSticker
          infoStickerHeader={infoStickerHeader}
          infoStickerPreamble1={infoStickerPreamble1}
          infoStickerPreamble2={infoStickerPreamble2}
          variant={variant}
        />
      )}
    </Wrapper>
  );
};

export default StickerPair;
