import { StickerVariant } from '@/newComponents/Sticker/StickerTypes';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import React from 'react';
import styled from 'styled-components';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

function getFontColor(variant?: StickerVariant) {
  switch (variant) {
    case StickerVariant.Light:
      return Color.Hallon1;
    case StickerVariant.Dark:
    default:
      return Color.Hallon2;
  }
}

const StyledSvg = styled(BaseSvgExtend)<{ $maxWidth: number }>`
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  width: 100%;
  height: auto;
  overflow: visible;
  user-select: none;
`;

export interface OvalStickerProps {
  priceStickerHeader: string;
  priceStickerPreheader?: string;
  priceStickerSubheader?: string;
  priceStickerPreamble?: string;
  variant?: StickerVariant;
  isMobile?: boolean;
  className?: string;
}

const StickerBackground = styled.div`
  transform: rotate(7deg);
  background-color: ${Color.Lemon1};

  padding: ${spacing.x2};
  border-radius: ${NewBorderRadius.Large};
  z-index: 1;
  user-select: none;
`;

const RectangularSticker = ({
  className,
  variant,
  priceStickerPreheader,
  priceStickerSubheader,
  priceStickerHeader,
  priceStickerPreamble,
}: Omit<OvalStickerProps, 'isMobile'>) => {
  const fontColor = getFontColor(variant);

  return (
    <StickerBackground className={className}>
      <Typography
        align="center"
        color={fontColor}
        type={TypographyType.Body}
        fontWeight={FontWeight.Medium}
        text={priceStickerPreheader}
      />
      <Typography
        align="center"
        color={fontColor}
        type={TypographyType.HeadingL}
        text={priceStickerHeader}
        elementAs="span"
      />
      <Typography
        align="center"
        color={fontColor}
        type={TypographyType.Body}
        fontWeight={FontWeight.Medium}
        text={priceStickerSubheader}
      />
      <Typography align="center" color={fontColor} text={priceStickerPreamble} />
    </StickerBackground>
  );
};

const OvalSticker = (props: OvalStickerProps) => {
  const formattedProps = {
    ...props,
    priceStickerPreheader: props.priceStickerPreheader?.toUpperCase(),
    priceStickerPreamble: props.priceStickerPreamble?.toUpperCase(),
    priceStickerSubheader: props.priceStickerSubheader?.toUpperCase(),
  };

  const {
    className,
    variant,
    priceStickerPreheader,
    priceStickerSubheader,
    priceStickerHeader,
    priceStickerPreamble,
    isMobile,
  } = formattedProps;

  const viewBoxHeight = isMobile ? 120 : 176;
  const viewBoxWidth = isMobile ? 192 : 269;
  const centerY = viewBoxHeight / 2;
  const centerX = viewBoxWidth / 2;
  const YRadius = centerY;
  const XRadius = centerX;

  const textPadding = 15;
  const headerLineHeightCompensation = 5;

  const fontColor = getFontColor(variant);
  const headerFontSize = isMobile ? 44 : 88;
  const subHeaderFontSize = isMobile ? 14 : 16;

  const componentWidth = isMobile ? 224 : 269;

  if (priceStickerPreamble) {
    return <RectangularSticker {...formattedProps} />;
  }

  return (
    <StyledSvg className={className} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} $maxWidth={componentWidth}>
      <g transform={`translate(${centerX}, ${centerY}) rotate(7) translate(-${centerX}, -${centerY})`}>
        <path
          id="background-ellipsis"
          d={`M ${centerX - XRadius} ${centerY}
            A ${XRadius} ${YRadius} 0 1 0 ${centerX + XRadius} ${centerY}
            A ${XRadius} ${YRadius} 0 1 0 ${centerX - XRadius} ${centerY}`}
          fill={Color.Lemon1}
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="top-arch"
          d={`M ${centerX - XRadius} ${centerY}
            A ${XRadius} ${YRadius - textPadding} 0 1 1 ${centerX + XRadius} ${centerY}`}
          fill="none"
        />
        <path
          id="bottom-arch"
          d={`M ${centerX - XRadius} ${centerY}
            A ${XRadius} ${YRadius - textPadding} 0 1 0 ${centerX + XRadius} ${centerY}`}
          fill="none"
        />
        <path id="center-line" d={`M 0 ${centerY + headerLineHeightCompensation} H ${viewBoxWidth}`} stroke="none" />

        <text fill={fontColor}>
          <textPath
            xlinkHref="#top-arch"
            fontSize={subHeaderFontSize}
            letterSpacing="1.5"
            startOffset="50%"
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {priceStickerPreheader}
          </textPath>
          <textPath
            xlinkHref="#bottom-arch"
            fontSize={subHeaderFontSize}
            letterSpacing="1.5"
            startOffset="50%"
            textAnchor="middle"
          >
            {priceStickerSubheader}
          </textPath>
          <textPath
            xlinkHref="#center-line"
            textAnchor="middle"
            dominantBaseline="middle"
            startOffset={isMobile ? '52%' : '50%'}
            fontSize={headerFontSize}
            fontWeight={FontWeight.SemiBold}
          >
            {priceStickerHeader}
          </textPath>
        </text>
      </g>
    </StyledSvg>
  );
};

export default OvalSticker;
